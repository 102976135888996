
.container.full-height {
  position: relative;
  min-height: calc(100vh - 80px);
}
#chat {
  overflow: hidden;
  padding-right: 20px;
  padding-bottom: 30px;
  overflow-y: auto;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column-reverse;
  &.limitheight {
    max-height: calc(100vh - 160px);
  }
}
#answer-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
}
