
.control {
  width: 100%;
  .button-in-input {
    transition: all 0.2s;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100 !important;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .input:focus + .button-in-input {
    opacity: 1;
  }
}
